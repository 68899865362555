<template>
  <div class="top-right-btn">
    <el-row>
      <el-tooltip v-if="isShowSearch" class="item" effect="dark" :content="showSearch ? '隐藏搜索' : '显示搜索'" placement="top">
        <el-button size="mini" circle icon="el-icon-search" @click="toggleSearch()" />
      </el-tooltip>
      <el-tooltip v-show="isChart" class="item" effect="dark" :content="showChart ? '隐藏图表' : '显示图表'" placement="top">
        <el-button size="mini" circle icon="el-icon-data-line" @click="toggleChart()" />
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="刷新" placement="top">
        <el-button size="mini" circle icon="el-icon-refresh" @click="refresh()" :disabled="refreshDisabled"/>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="显隐列" placement="top" v-if="columns">
        <el-button size="mini" circle icon="el-icon-menu" @click="showColumn()" />
      </el-tooltip>
      <el-tooltip class="item" style="padding-left: 7px" effect="dark" content="列表设置" placement="top" v-if="columnsList.length">

        <el-dropdown trigger="click">
          <el-button size="mini" circle icon="el-icon-s-operation"/>
          <el-dropdown-menu slot="dropdown">
            <span class="title">列设置</span>
            <el-tree draggable :data="columnsList" :props="defaultProps" :allow-drop="allowDrop">
      <span class="tree-table-setting" slot-scope="{ node, data }">
        <el-switch  v-model="data.show" :active-color="isDark ? '#03a9a7' : '#1890ff'"
                   inactive-color="#ff4949"> </el-switch>
        <span :style="{marginLeft: '5px', color: data.show? (isDark ? '#03a9a7' : '#1890ff'):'#ff4949'}">{{ node.label }}</span>
      </span>
            </el-tree>
          </el-dropdown-menu>
        </el-dropdown>
      </el-tooltip>
    </el-row>
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-transfer
        :titles="['显示', '隐藏']"
        v-model="value"
        :data="columns"
        @change="dataChange"
      ></el-transfer>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "RightToolbar",
  data() {
    return {
      // 显隐数据
      value: [],
      // 弹出层标题
      title: "显示/隐藏",
      // 是否显示弹出层
      open: false,
      refreshDisabled: false,
    };
  },
  props: {
    isChart: {
      type: Boolean,
      default: false,
    },
    showChart: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },

    isShowSearch: {
      type: Boolean,
      default: true,
    },

    columns: {
      type: Array,
    },

    columnsList: {
      type: Array,
      default: ()=> []
    },

    defaultProps: {
      type: Object,
      default:  () => {
        return {
          children: 'children',
          label: 'label'
        }
      }
    },
  },
  created() {
    // 显隐列初始默认隐藏列
    for (let item in this.columns) {
      if (this.columns[item].visible === false) {
        this.value.push(parseInt(item));
      }
    }
  },
  methods: {
    // 搜索
    toggleSearch() {
      this.$emit("update:showSearch", !this.showSearch);
    },
    // 搜索
    toggleChart() {
      this.$emit("update:showChart", !this.showChart);
    },
    // 刷新
    refresh() {
      this.$emit("queryTable");
    },
    // 右侧列表元素变化
    dataChange(data) {
      for (let item in this.columns) {
        const key = this.columns[item].key;
        this.columns[item].visible = !data.includes(key);
      }
    },
    // 打开显隐列dialog
    showColumn() {
      this.open = true;
    },

    allowDrop(draggingNode, dropNode, type) {
      // 仅允许Tree节点上下拖动
      return type !== 'inner'
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-transfer__button {
  border-radius: 50%;
  padding: 12px;
  display: block;
  margin-left: 0;
}
::v-deep .el-transfer__button:first-child {
  margin-bottom: 10px;
}

.title {
  font-size: 14px;
  display: block;
  text-align: center;
  padding: 6px 0 10px 0;
}


::v-deep .el-tree {
  font-size: 13px;
  padding-right: 20px;

  > div:not(:first-child) {
    margin-top: 5px;
  }
}

</style>
