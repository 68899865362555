<template>
  <div id="app" :class="'theme-' + wholeTheme" class="new-style">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'App',

  provide() {
    return {
      reload: this.reload
    }
  },

  data() {
    return {
      isRouterAlive: true,
    }
  },

  methods: {
    //重置
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
  },

  computed: {
    ...mapState(['settings']),

    wholeTheme() {
      return this.$store.state.settings.wholeTheme
    }
  },
  watch: {
    wholeTheme: {
      deep: true,
      immediate: true,

      handler(val) {
        document.body.className = val === 'dark' ? 'dark' : ''
      }
    }
  },
  metaInfo() {
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: title => {
        return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
      }
    }
  }
}
</script>

<style lang="scss">
@import "../public/theme/dark/styles.scss";
#app {
  overflow: auto;
}
</style>
